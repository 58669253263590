import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Container from "../components/Container"
import styled from "styled-components"
import { Link } from "gatsby"

const SuccessContainer = styled(Container)`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Success = () => (
  <Layout showNav={false}>
    <SEO title="WILL-DO Kiitos yhteydenotosta!" />
    <SuccessContainer>
      <div>
        {" "}
        <h1>Kiitos yhteydenotosta</h1>
        <Link to="/">Takaisin etusivulle</Link>
      </div>
    </SuccessContainer>
  </Layout>
)

export default Success
